<template>
  <div>
    <div class="col-12 layout-spacing">
      <div class="widget widget-table-three">
        <div class="widget-heading">
          <h5 class="">Doctors By Location</h5>
        </div>
        <div class="p-2 text-center" v-if="loadingGERO">
          <b-card class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="95%"></b-skeleton>
            <b-skeleton animation="wave" width="79%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="86%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
          </b-card>
        </div>
        <div class="widget-content" v-else>
          <apexchart
            type="treemap"
            height="350"
            :options="doctorchartOptions"
            :series="doctorseries"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="col-12 layout-spacing">
      <div class="widget widget-table-three">
        <div class="widget-heading">
          <h5 class="">Elderlies By Location</h5>
        </div>
        <div class="p-2 text-center" v-if="loadingGERO">
          <b-card class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="95%"></b-skeleton>
            <b-skeleton animation="wave" width="79%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="86%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
          </b-card>
        </div>
        <div class="widget-content" v-else>
          <apexchart
            type="treemap"
            height="350"
            :options="elderchartOptions"
            :series="elderseries"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="col-12 layout-spacing">
      <div class="widget widget-table-three">
        <div class="widget-heading">
          <h5 class="">Growth</h5>
        </div>
        <div class="p-2 text-center" v-if="loadingGERO">
          <b-card class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="95%"></b-skeleton>
            <b-skeleton animation="wave" width="79%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="86%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
          </b-card>
        </div>
        <div class="widget-content" v-else>
          <apexchart
            type="line"
            height="350"
            :options="growthchartOptions"
            :series="growthseries"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="col-12 layout-spacing">
      <div class="widget widget-table-three">
        <div class="widget-heading">
          <h5 class="">Sources - How did you hear about us?</h5>
        </div>
        <div class="p-2 text-center" v-if="loadingGERO">
          <b-card class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="95%"></b-skeleton>
            <b-skeleton animation="wave" width="79%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="86%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
          </b-card>
        </div>
        <div class="widget-content" v-else>
          <apexchart
            type="bar"
            height="350"
            :options="sourceschartOptions"
            :series="sourcesseries"
          ></apexchart>
        </div>
      </div>
    </div>

    <div class="col-12 layout-spacing">
      <div class="widget widget-table-three">
        <div class="widget-heading">
          <h5 class="">Visits Paid For</h5>
        </div>
        <div class="p-2 text-center" v-if="loadingGERO">
          <b-card class="">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="65%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="95%"></b-skeleton>
            <b-skeleton animation="wave" width="79%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="86%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
            <b-skeleton animation="wave" width="78%"></b-skeleton>
            <b-skeleton animation="wave" width="98%"></b-skeleton>
            <b-skeleton animation="wave" width="90%"></b-skeleton>
          </b-card>
        </div>
        <div class="widget-content" v-else>
          <apexchart
            type="area"
            height="350"
            :options="visitsPaidForChartOptions"
            :series="visitsPaidForSeries"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { gerohttp } from "@/utils/gerocarehttp";
import { geroendpoints } from "@/utils/endpoints";

export default {
  name: "GerocareAnalytics",
  data() {
    return {
      loadingGERO: true,
      // doctor locations
      doctorseries: [
        {
          data: [],
        },
      ],
      doctorchartOptions: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        colors: [
          "#3B93A5",
          "#F7B844",
          "#ADD8C7",
          "#EC3C65",
          "#CDD7B6",
          "#C1F666",
          "#D43F97",
          "#1E5D8C",
          "#421243",
          "#7F94B0",
          "#EF6537",
          "#C0ADDB",
          "#C0AD7B",
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },
      // elderly locations
      elderseries: [
        {
          data: [],
        },
      ],
      elderchartOptions: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        colors: ["#049a58"],
      },
      // user growth
      growthseries: [],
      growthchartOptions: {},
      // sources series
      sourcesseries: [],
      sourceschartOptions: {},
      // visits paid for series
      visitsPaidForSeries: [],
      visitsPaidForChartOptions: {},
    };
  },
  computed: {
    ...mapGetters(["url", "user", "gcurl", "michurl"]),
  },
  methods: {
    fetchDataFromGeroCare() {
      this.loadingGERO = true;

      gerohttp
        .get(geroendpoints.ANALYTCIS_DATA)
        .then((response) => {
          this.growthchartOptions = {
            chart: {
              height: 350,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: true,
              },
            },
            colors: ["#c20ce0", "#47c10b", "#1809e0"],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "smooth",
            },
            title: {
              text: "",
              align: "left",
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 1,
            },
            xaxis: {
              categories: response.categories,
              title: {
                text: "Month",
              },
            },
            yaxis: {
              title: {
                text: "Registration",
              },
            },
            legend: {
              position: "top",
              horizontalAlign: "right",
              floating: true,
              offsetY: -25,
              offsetX: -5,
            },
          };

          this.visitsPaidForChartOptions = {
            chart: {
              type: "area",
              height: 350,
              stacked: false,
            },
            colors: ["#00E396", "#008FFB", "#ef3507", "#6616cc"],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "smooth",
            },
            fill: {},
            legend: {
              position: "top",
              horizontalAlign: "left",
            },
            xaxis: {
              categories: response.categories,
            },
          };

          (this.sourceschartOptions = {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              //stackType: '100%',
              toolbar: {
                show: true,
              },
            },
            plotOptions: {},
            colors: [
              "#008FFB",
              "#ef3507",
              "#dbf709",
              "#bf4e67",
              "#793a84",
              "#131007",
              "#049a58",
              "#c2a47599",
            ],
            dataLabels: {
              enabled: true,
              // formatter: function (val, opts) {
              //     return `${Math.round(val)}%`
              // },
            },
            yaxis: {
              title: {
                text: "how did you hear about us?",
              },
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return Math.round(val);
                },
              },
            },
            xaxis: {
              categories: response.categories,
            },
          }),
            (this.doctorseries = response.doctors);
          this.elderseries = response.patients;
          this.growthseries = response.growth;
          this.sourcesseries = response.sources;
          this.visitsPaidForSeries = response.payments;

          this.loadingGERO = false;
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
  },
  mounted() {
    this.fetchDataFromGeroCare();
  },
};
</script>

<style>
.badge {
  line-height: 1.4;
  padding: 3px 6px;
  font-size: 12px;
  font-weight: 600;
}

.upcomingproj {
  height: 40vh !important;
  overflow: scroll;
}
</style>
